//
// custom-variables.scss
//

//Background left-sidebar
$bg-leftbar: var(--#{$variable-prefix}bg-leftbar);
$bg-leftbar-dark: var(--#{$variable-prefix}bg-leftbar-dark);
$bg-leftbar-brand: var(--#{$variable-prefix}bg-leftbar-brand);
$bg-leftbar-gradient: var(--#{$variable-prefix}bg-leftbar-gradient);

// user box
$left-menu-user-box-border: var(--#{$variable-prefix}left-menu-user-box-border);
$left-menu-dark-user-box-border: var(--#{$variable-prefix}left-menu-dark-user-box-border);

// Leftbar width
$leftbar-width: 240px;
$leftbar-width-condensed: 70px;

// Small Leftbar Width
$leftbar-width-sm: 160px;

// Boxed layout width
$boxed-layout-width: 1300px;

// Dark leftbar menu color
$menu-item-color-dark: var(--#{$variable-prefix}menu-item-color-dark);
$menu-item-hover-color-dark: var(--#{$variable-prefix}menu-item-hover-color-dark);
$menu-item-active-color-dark: var(--#{$variable-prefix}menu-item-active-color-dark);

//Menu item
$menu-item: var(--#{$variable-prefix}menu-item);
$menu-item-hover: var(--#{$variable-prefix}menu-item-hover);
$menu-item-active: var(--#{$variable-prefix}menu-item-active);
$menu-sub-item-active: var(--#{$variable-prefix}menu-sub-item-active);
$menu-item-size: 0.95rem;
$menu-sub-item-size: 0.875rem;

// Horizontal Menu Item
$hori-menu-item-color: var(--#{$variable-prefix}hori-menu-item-color);

//Rightbar Width
$rightbar-width: 260px;
$rightbar-bg: var(--#{$variable-prefix}rightbar-bg);

//Topbar Background
$bg-topbar-light: var(--#{$variable-prefix}bg-topbar-light);
$bg-topbar-dark: var(--#{$variable-prefix}bg-topbar-dark);

// Leftbar item shadow (Condensed item)
$box-shadow-condensed: var(--#{$variable-prefix}box-shadow-condensed);

// Page title
$page-title-color: var(--#{$variable-prefix}page-title-color);

// Topbar Search
$topbar-search-bg: var(--#{$variable-prefix}topbar-search-bg);
$topbar-search-bg-dark: var(--#{$variable-prefix}topbar-search-bg-dark);

// Topnav Height (Horizontal)
$topnav-height: 55px;

// Topbar Height
$topbar-height: 70px;

// Footer
$footer-bg: var(--#{$variable-prefix}footer-bg);
$footer-color: var(--#{$variable-prefix}footer-color);
$footer-link-hover-color: var(--#{$variable-prefix}footer-link-hover-color);

// Logo box
$logo-box-background: var(--#{$variable-prefix}logo-box-background);

// Horizontal Layout
$horizontal-layout-width: 90%;

// Body minimum height
$body-min-height: 1750px;

// Secondary font
$font-family-secondary: "Karla", sans-serif;

// Font weight
$font-weight-medium: 500;
$font-weight-semibold: 600;

// Dropdown Large
$dropdown-lg-width: 320px;

// Logo Display
$logo-dark-display: none;
$logo-light-display: block;

// Logo Display - Auth Pages
$logo-auth-dark-display: block;
$logo-auth-light-display: none;

// User authentication Background
$auth-bg: var(--#{$variable-prefix}auth-bg);
$auth-bg-alt: var(--#{$variable-prefix}auth-bg-alt);
//$auth-img-bg: url("../images/bg-auth3.png");
//$auth-img-bg-dark: url("../images/bg-auth-dark.jpg");
//$auth-img-pattern-bg: url("../images/bg-pattern.png");

$auth-img-bg: url("../images/fondologin.jpg");
$auth-img-bg-dark: url("../images/fondologin.jpg");
$auth-img-pattern-bg: url("../images/fondologin.jpg");

// Chat widget
$chat-primary-user-bg: var(--#{$variable-prefix}chat-primary-user-bg);
$chat-secondary-user-bg: var(--#{$variable-prefix}chat-secondary-user-bg);

// Card overlay
$card-overlay-bg: var(--#{$variable-prefix}card-overlay-bg);

// Input light gray
$input-light-bg: var(--#{$variable-prefix}input-light-bg);

// Components Shadows
$components-shadow-sm: none;
$components-shadow: none;
$components-shadow-lg: none;

// label
$label-font-weight: 500;

// Table Head
$table-head-font-weight: 700;

// Font weight
$font-weight-medium: 500;
$font-weight-semibold: 600;

// Email
$email-msg-container-bg: var(--#{$variable-prefix}email-msg-container-bg);

// Apex chart
$apex-grid-color: var(--#{$variable-prefix}apex-grid-color);
