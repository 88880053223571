//
// card.scss
//

.card-login{
    box-shadow: $box-shadow-sm;
    margin-bottom: $grid-gutter-width;
    //background-color:#ecf0f5;//#e9f6fa;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.5);

    .card-drop {
        color: #{map-get($grays, "600")};
        font-size: 20px;
        display: inline-block;
        line-height: 1px;
    }
}

.card {
    box-shadow: $box-shadow-sm;
    margin-bottom: $grid-gutter-width;
    background-color:#ecf0f5;//#e9f6fa;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    //background-color: rgba(0, 0, 0, 0.5);

    .card-drop {
        color: #{map-get($grays, "600")};
        font-size: 20px;
        display: inline-block;
        line-height: 1px;
    }
}


.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: "\F0415";
                }
            }
        }
    }
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}


//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: $card-overlay-bg;
    cursor: progress;

}


.header-title {
    font-size: 1rem;
    margin: 0 0 7px 0;
}

.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $text-muted;
}





// dark mode
body[data-layout-color="dark"] {
    .card {
        .card-drop {
            color: #{map-get($dark-grays, "600")};
        }
    }
}
